.outerDiv{
    width: 100%;
    margin: 10vw 0 0 0;
    text-align: center;
    align-items: center;
}
.titleName{
    font-size: 2vw;
    font-weight: bold;
    letter-spacing: 0.1vw;
}
.debtTable{
    margin: 2vw auto;
    width: 70%;
    text-align: left;
    /* border: 1px solid black; */
    border-collapse: collapse;
    color: rgb(48, 47, 47);
    letter-spacing: 0.07vw;
    font-size: 0.83vw;
}
.debtTable td,
.debtTable th{
    padding: 0.7vw 1vw;
}
.debtTable tr{
    border: 0.2vw solid white;
}
.headerRow{
    background-color: rgb(48, 47, 47);;
    color: white;
}
.qarzdor{
    background-color: yellow;
}
.qarzdoremas{
    background-color: chartreuse;
}
.debtTable1{
    position: relative;
    margin: 2vw auto;
    width: 70%;
    text-align: left;
    /* border: 1px solid black; */
    border-collapse: collapse;
    color: rgb(48, 47, 47);
    letter-spacing: 0.07vw;
    font-size: 0.83vw;
}
.debtTable1::after{
    position: absolute;
    content: "Aylanma varaqa shakilantrilmagan!";
    font-size: 3.8vw;
    /* z-index: -1;  */
    /* opacity: 0.5; */
    top: 35%;  
    left: 0;
    color: red;
    transform: rotate(348deg);
}
.debtTable1 td,
.debtTable1 th{
    padding: 0.7vw 1vw;
    height: 0.83vw;
}
.debtTable1 tr{
    border: 0.2vw solid white;
}
@media screen and (max-width: 600px) {
    .outerDiv{
        margin: 25vw 0 0 0;
    }
    .titleName{
        font-size: 4vw;
        letter-spacing: 0.2vw;
    }
    .debtTable{
        margin: 4vw auto;
        width: 95%;
        letter-spacing: 0.01vw;
        font-size: 1.8vw;
    }
    .debtTable td,
    .debtTable th{
        padding: 1vw 1.5vw;
    }
    .debtTable tr{
        border: 0.5vw solid white;
    }
    .debtTable1{
        margin: 4vw auto;
        width: 95%;
        letter-spacing: 0.01vw;
        font-size: 1.8vw;
    }
    .debtTable1::after{
        position: absolute;
        content: "Aylanma varaqa shakllantirilmagan!";
        font-size: 5.2vw;
        /* z-index: -1;  */
        /* opacity: 0.5; */
        top: 39%;  
        overflow: hidden;
        left: 0;
        color: red;
        transform: rotate(344deg);
        overflow: hidden;
    }
    .debtTable1 td,
    .debtTable1 th{
        padding: 1vw 1.5vw;
        height: 1.83vw;
    }
    .debtTable1 tr{
        border: 0.5vw solid white;
    }
}