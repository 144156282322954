  
.ProfileTableWrapper {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.83vw;
}

.ProfileTableWrapper td, .ProfileTableWrapper th {
  border: 0.1vw solid #ddd;
  padding:  0.5vw;
  letter-spacing: 0.03vw;
}
.ProfileTableWrapper tr:nth-child(even){background-color: #f2f2f2;}

.ProfileTableWrapper tr:hover {background-color: #ddd;}

.ProfileTableWrapper th {
  text-align: left;
  background-color: #2268A9;
  color: white;
}
.tableTd td {
  min-width: 30vw;
}
@media screen and (max-width: 600px) {
  .ProfileTableWrapper {
    font-size: 1.4vw;
  }
  .ProfileTableWrapper td, .ProfileTableWrapper th {
    border: 0.2vw solid #ddd;
    padding:  1vw;
    letter-spacing: 0.04vw;
  }

}