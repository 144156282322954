@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
*{
    font-family: 'Merriweather', serif;
}

.nav1{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}
.nav1 .list1{
    display: inline-block;
    align-items: center;
    text-align: center;
}
.nav1 .list1:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease;
}
.nav1 .list1:hover:after {
    width: 100%;
    background: rgb(33,97,164);
}
.nav1 .list1 .text > :first-child{
    display: inline-block;
    text-align: center;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.5vw;
}