.selectInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0.5vw 1vw;
  font-size: 0.83vw;
  border: 0.1vw solid #eee;
  background-color: white;
  border-radius: 0.3vw;
  outline: none;
  margin: 1vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.selectInput1 {
  width: 60%;
}
.selectInput:required:invalid {
    color: gray;
}
.selectInput option[value=""][disabled] {
  display: none;
}
.selectInput option {
  color: black;
  text-align: left;
  box-sizing: content-box;
  padding: 5px 0;
  font-size: 0.83vw;
  font-family: inherit;
}
.selectInput option:hover{
  background-color: #1C9FD0;
}
.table-wrapper {
  margin: 4vw auto;
  overflow: auto;
  max-width: 96%;
  background:
    linear-gradient(to right, white 30%, rgba(255,255,255,0)),
    linear-gradient(to right, rgba(255,255,255,0), white 70%) 0 100%,
    radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
  font-size: 0.83vw;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.table-wrapper .type{
  width: 50vw;
  padding: 0.5vw;
}
.table-wrapper .bodyIcon{
  color: #1AB0DC;
  font-size: 1.5vw;
}
.table-wrapper tr:nth-child(odd) {
  background-color: #eee;
}
.table-wrapper th {
  background-color: #555;
  color: #fff;
}

.table-wrapper th,
.table-wrapper .bodyTable {
  text-align: center;
  padding: 0.5vw;
}
@media screen and (max-width: 600px) {
  .selectInput {
    padding: 3vw 3.5vw;
    font-size: 3.5vw;
    border: 0.3vw solid #eee;
    border-radius: 0.5vw;
    margin: 3vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .selectInput1 {
    width: auto;
  }
  .selectInput option {
    font-size: 2.5vw;
  }
  .selectInput option:hover{
    background-color: #1C9FD0;
  }
  .table-wrapper {
    font-size: 3.5vw;
    max-width: 90%;
  }
  .table-wrapper .type{
    width: 90vw;
    padding: 3vw 1vw;
  }
  .table-wrapper .bodyIcon{
    font-size: 6vw;
  }
  
  .table-wrapper th,
  .table-wrapper .bodyTable {
    text-align: center;
    padding: 2vw;
  }
}